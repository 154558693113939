import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import ContentImage from "../components/ContentImage"
import Guide from "../components/Guide"
import TextImage from "../components/TextImage"
import { OgTags } from "../components/OpenGraph";

const cta = {
    hasBackground: false,
    hasButton: true,
    imagePrimary: true,
    // isFullContent: false,
    // styleA: true,
    title: "Fullstack HQ Affiliate Program",
    desc: "Earn passive residual income and provide discount to your referrals by joining our affiliate program",
    image: require('../assets/images/mascot-affiliate.png'),
    buttons: [
        {
        type: 'normal',
        text: "Become an affiliate",
        link: '/',
        beacon: true
        }
    ]
}

const guideSwiperContent = [
  {
    guideImage: require('../assets/images/steps-img1.png'),
    number: '1',
    title: 'Join',
    desc: 'Simply'
  },
  {
    guideImage: require('../assets/images/steps-img1.png'),
    number : '2',
    title: 'Promote',
    desc: 'Simply'
  },
  {
    guideImage: require('../assets/images/steps-img1.png'),
    number : '3',
    title: 'Earn',
    desc: 'Simply'
  },
  {
    guideImage: require('../assets/images/steps-img1.png'),
    number : '4',
    title: 'Discount',
    desc: 'Simply'
  }
]

const stepsContent = [
  {
    number: '1',
    title: 'Join',
    desc: 'Simply fill out an application today and see if you qualify for our affiliate program'
  },
  {
    number : '2',
    title: 'Promote',
    desc: 'Create affiliate links then spread the word by email or with your link via social sharing'
  },
  {
    number : '3',
    title: 'Earn',
    desc: 'Earn 10% monthly commission for every referral for the lifetime of that client'
  },
  {
    number : '4',
    title: 'Discount',
    desc: 'Your referral will receive 10% off their first month of Fullstack when they use your code'
  }
]
const textContent = {
  title: "Become and affiliate today and we'll pay you 10% each month as long as your referral stays. Your code also grants each referral 10% off their month.",
  image: require('../assets/images/embedded-image.png'),
  name: "form embed"
}


function Affiliate() {
    return (
      <>
        <OgTags
          ogTitle="Affiliate Page | Fullstack HQ"
          // ogDescription="Premium WordPress maintenance with unlimited web edits and fixes for only $99 per month. Learn more with your free trial today."
          ogUrl="https://fullstackhq.com/affiliate"
        />
        <Header />
          <main className="affiliate initialized-header">
            <ContentImage
              content={ cta }
            />
            <Guide
              heading = "How it Works"
              guideSwiper ={guideSwiperContent}
              guide ={stepsContent}
            />

            <TextImage
              content={ textContent }
            />
          </main>
        <Footer />
      </>
    )
  }

  export default Affiliate
