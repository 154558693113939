import React from "react"
import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/css/swiper.css"

const params = {
  spaceBetween: 30,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
}

function Guide(props) {
  const {heading, guide, guideSwiper} = props

  return (
    <div className="section-guide">
      <div className="container">
        <h2 className="text-center">{heading}</h2>
        <Swiper {...params}>
          {guideSwiper.map((item, i) => {
            return (
              <div key={i}  className="swiper-slide">
                <div className="image-holder">
                  <img src={item.guideImage} className="img-fluid" alt="Become an Affiliate Guide"/>
                </div>
              </div>
            )
          })}
        </Swiper>
        <div className="steps-wrapper">
          {guide.map((steps, i) => {
            return (
              <div key={i} className="steps-holder">
                <p className="steps-number">{steps.number}</p>
                <div className="steps-desc-holder">
                  <p className="steps-title">{steps.title}</p>
                  <p className="steps-desc">{steps.desc}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Guide
